<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <page-loading :loading="initializing" />
    <div v-if="!initializing">
      <m-card :title="$t('shared.edit')">
        <base-form v-model="model" />

        <template #actions>
          <page-footer edit @submit="handleSubmit(submit)" :loading="progressing" />
        </template>
      </m-card>

      <UsersTable
        v-if="$hasPermission('user-management.operation')"
        class="mb-3"
        :working-site-id="id"
        :branch-id="model.organizationId"
      />

      <template v-if="$hasPermission('dashboard.operation')">
        <ActivityChart class="mb-3" :working-site-id="id" />
        <UvChart class="mb-3" :working-site-id="id" />
        <UvAlerts class="mb-3" :working-site-id="id" />
        <ScreenAlerts class="mb-3" :working-site-id="id" />
      </template>
    </div>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
import UsersTable from '@/pages/base/components/UsersTable'
import ActivityChart from '@/pages/base/components/ActivityChart'
import UvChart from '@/pages/base/components/UvChart'
import UvAlerts from '@/pages/base/components/UvAlerts'
import ScreenAlerts from '@/pages/base/components/ScreenAlerts'

export default {
  name: 'Edit',
  props: ['id'],
  mixins: [EditForm],
  components: {
    UsersTable,
    ActivityChart,
    UvChart,
    ScreenAlerts,
    UvAlerts
  },
  created() {
    this.getModel()
      .catch((err) => {
        this.$showError(err)
      })
      .finally(() => {
        this.initializing = false
      })
  },
  methods: {
    getModel() {
      return this.$api.workingSites.getById(this.id).then((res) => {
        this.model = res
        this.$setBreadcrumbs([
          {text: this.$t('nav.workingSites'), to: '/working-sites'},
          {text: res.name, disabled: true}
        ])
      })
    },
    submit() {
      this.progressing = true
      return this.$api.workingSites
        .update(this.id, this.model)
        .then((res) => {
          this.$showSuccess(this.$t('workingSites.workingSiteUpdated'))
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    }
  }
}
</script>
