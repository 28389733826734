<template>
  <v-card>
    <v-overlay absolute :value="progressing" opacity="0.3">
      <v-progress-circular indeterminate color="primary" class="mb-0" size="64"></v-progress-circular>
    </v-overlay>

    <v-card-title class="text-h5">{{ $t('nav.users') }}</v-card-title>
    <v-card-text>
      <div v-if="workingSiteId || branchId" class="mb-3">
        <v-row dense>
          <v-col>
            <auto-complete
              v-model="model.userId"
              :label="$t('users.addNewUser')"
              inner-label
              :search="searchUser"
              hide-details
              item-text="fullName"
            />
          </v-col>

          <v-col cols="auto">
            <v-btn color="primary" elevation="0" @click="addUserToTarget" :disabled="!model.userId">
              <v-icon small> mdi-plus </v-icon>
              {{ $t('shared.add') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <search-form>
        <div class="d-inline-flex flex-wrap mb-3">
          <c-input v-model="search.id" label="ID" search-form />
          <c-input v-model="search.firstName" :label="$t('shared.firstName')" search-form />
          <c-input v-model="search.lastName" :label="$t('shared.lastName')" search-form />
          <mobile-input v-model="search.mobile" :label="$t('shared.mobile')" search-form />
        </div>

        <div class="ma-1">
          <v-btn color="success" dark @click="filtering" small :loading="progressing">
            <v-icon small class="me-1"> mdi-filter </v-icon>
            {{ $t('shared.applyFilter') }}
          </v-btn>
        </div>
      </search-form>
    </v-card-text>

    <v-data-table
      :items="items"
      :headers="headers"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-0"
    >
      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <v-tooltip v-if="$hasPermission('identity.roles') && !workingSiteId" top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="warning" :to="`/users/${item.user.id}/branch/${branchId}/roles`" v-on="on">
                <v-icon> mdi-account-key </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.permissions') }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="info" :to="`/users/edit/${item.user.id}`" v-on="on">
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.edit') }}</span>
          </v-tooltip>

          <v-tooltip v-if="isBranch" top>
            <template v-slot:activator="{on}">
              <v-btn small fab text color="error" @click="removeUserFromTarget(item)" v-on="on">
                <v-icon> mdi-exit-to-app </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.exit') }}</span>
          </v-tooltip>

          <v-tooltip v-else-if="$hasPermission('user-management.operation')" top>
            <template v-slot:activator="{on}">
              <v-btn small fab v-on="on" text color="error" @click="removeItem(item)">
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('shared.delete') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.image`]="{item}">
        <Thumbnail v-if="item.profile.image" :src="item.profile.image" width="40" height="40" />
      </template>

      <template #[`item.mobile`]="{item}">
        {{ item.user.mobile }}
      </template>

      <template #[`item.id`]="{item}">
        {{ item.user.id }}
      </template>

      <template #[`item.firstName`]="{item}">
        {{ item.user.firstName }}
      </template>

      <template #[`item.lastName`]="{item}">
        {{ item.user.lastName }}
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'Index',
  props: {
    branchId: {type: [String, Number], default: null},
    workingSiteId: {type: [String, Number], default: null}
  },
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      model: {
        organizationId: this.branchId || null,
        workingSiteId: this.workingSiteId || null,
        userId: null
      },
      headers: [
        {text: 'ID', value: 'id'},
        {text: this.$t('shared.image'), value: 'image'},
        {text: this.$t('shared.firstName'), value: 'firstName', sortable: true},
        {text: this.$t('shared.lastName'), value: 'lastName'},
        {text: this.$t('shared.mobile'), value: 'mobile'},
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      items: [],
      search: {
        id: null,
        firstName: null,
        lastName: null,
        mobile: null,
        email: null,
        verified: null
      }
    }
  },
  computed: {
    isBranch() {
      return this.workingSiteId || this.branchId
    }
  },
  methods: {
    async addUserToTarget() {
      try {
        if (this.model.organizationId && this.model.workingSiteId) {
          await this.$api.users.addToWorkingSite(this.model)
          this.$showSuccess(this.$t('users.userAddedToWorkingSite'))
        } else if (this.model.organizationId) {
          await this.$api.users.addToOrganization(this.model)
          this.$showSuccess(this.$t('users.userAddedToOrganization'))
        } else {
          return
        }
        this.model.userId = null
        this.filtering()
      } catch (error) {
        this.$showError(error)
      }
    },
    async removeUserFromTarget(item) {
      const model = {
        ...this.model,
        userId: item.user.id
      }
      try {
        if (model.organizationId && model.workingSiteId) {
          await this.$api.users.removeFromWorkingSite(model)
          this.$showSuccess(this.$t('users.userRemoveFromWorkingSite'))
        } else if (model.organizationId) {
          await this.$api.users.removeFromOrganization(model)
          this.$showSuccess(this.$t('users.userRemoveFromOrganization'))
        }
        this.filtering()
      } catch (error) {
        this.$showError(error)
      }
    },
    filter(isCount) {
      const filter = this.$filter()
      if (this.workingSiteId) {
        filter.workingSiteId = this.workingSiteId
      } else if (this.branchId) {
        filter.organizationId = this.branchId
      }

      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      return filter
    },
    searchUser(filter) {
      return this.$api.users.getAllInfo(filter)
    },
    getItems() {
      return this.$api.users.getAll(this.filter()).then((res) => {
        this.items = res
      })
    },
    getCount() {
      return this.$api.users.count(this.filter(true)).then((res) => {
        this.count = res
      })
    },
    async filtering() {
      try {
        this.progressing = true
        await Promise.all([this.getItems(), this.getCount()])
        this.progressing = false
      } catch (error) {
        this.$showError(error)
      }
    },
    async removeItem(item) {
      try {
        if (!(await this.$confirm())) return

        await this.$api.users.delete(item.user.id)
        this.filtering()
        this.$showSuccess(this.$t('users.userRemoved'))
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
